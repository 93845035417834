<template>
  <main>
    <NavOther />
    <article class="page-content">
      <slot></slot>
    </article>
    <NavrightHome />
    <Footer />
  </main>
</template>

<script>
import NavOther from "@/components/NavOther";
import NavrightHome from "@/components/NavrightHome";
import Footer from "@/components/Footer";

export default {
  name: "PageLayout",
  components: {
    NavOther,
    NavrightHome,
    Footer
  }
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  height: 100%;

  .page-content {
    margin: 8.64vw;
  }
}
</style>
