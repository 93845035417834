<template>
  <div class="site-footer">
    <div class="copyright">&copy; Mongolia.travel</div>
    <nav class="footer-menu">
      <ul>
        <li v-for="item in menuItems" :key="item.path">
          <router-link :to="item.path">{{item.title}}</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      menuItems: [
        {
          title: "Terms of Use",
          path: "/terms"
        },
        {
          title: "Cookies",
          path: "/cookies"
        },
        {
          title: "Privacy Statement",
          path: "/privacy"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.site-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  color: #2c2c2c;
  padding-left: 1.66vw;
  font-size: 12px;
  height: 40px;

  a {
    color: #2c2c2c;
    text-decoration: none;
    transition: 0.3s ease all;

    &:hover {
      color: #e13036;
    }
  }

  .footer-menu {
    ul {
      list-style: none;
      overflow: hidden;
      margin: 0;
      padding: 0;
      li {
        float: left;
        margin-left: 3.64vw;
      }
    }
  }
}
</style>
