<template>
  <PageLayout>
    <div class="privacy-page">
      <h1 class="header">Privacy Statement</h1>
        <div class="data-controller">
          <h6 class=" title-header">1. DATA CONTROLLER</h6>
          <p class="title">Mongolia Ministry of Environment and Tourism
Government Building-2, United Nation’s Street 5/2
Ulaanbaatar 15160
Mongolia 
         </p>
        </div>
        <div class="point-fo-contact">
          <h6 class=" title-header">2. POINT OF CONTACT</h6>
          <p class="title">Please e-mail: <a href="info@mongolia.travel" class="link">info@mongolia.travel</a></p>
        </div>
        <div class="purpose-of-presonal-data">
          <h6 class=" title-header">3. PURPOSE OF PERSONAL DATA PROCESSING AND BASIS FOR PROCESSING</h6>
          <p class="title">Processing tasks may be outsourced to third party service providers in 
accordance with the data protection legislation and the boundaries imposed by 
same.
         </p>
        </div>
        <div class="data-content">
          <h6 class=" title-header">4. DATA CONTENT OF REGISTER</h6>
          <p class="title">For example the following type of information regarding the data subject may be  
stored:
Name of person
Email address
Address
Telephone number
Nationality
Age
         </p>
        </div>
        <div class="personal-data-retention">
          <h6 class=" title-header">5. PERSONAL DATA RETENTION PERIOD</h6>
          <p class="title">Information shall be erased periodically, at least every 3 years, provided 
that there is no longer any need to process the event information.   
  
The erasing shall take place by means of deleting the information in its entirety, 
by rendering the data passive so that the data are no longer processed and 
access to the data is restricted, by means of encrypting or overwriting.
         </p>
        </div>
        <div class="regular-sources">
          <h6 class=" title-header">6. REGULAR SOURCES OF INFORMATION</h6>
          <p class="title">Information concerning the client contact persons shall be collected from the 
persons themselves. 
         </p>
        </div>
        <div class="regular-disclosures">
          <h6 class=" title-header">7. REGULAR DISCLOSURES OF INFORMATION AND RECIPIENT CATEGORIES</h6>
          <p class="title">Information may be disclosed within the framework of the laws of Mongolia.
Data may be disclosed to the Mongolia Ministry of Environment and Tourism’s 
co-operation partners for non-commercial purposes, for the purpose of 
arranging visits and events and for sending out various event/visit invitations.
         </p>
        </div>
        <div class="transfer-of-data">
          <h6 class=" title-header">8. TRANSFER OF DATA</h6>
          <p class="title">Personal data may be transferred  to other countries in accordance with the
data protection legislation and within the boundaries imposed by same. If no 
decision regarding an adequate level of data protection has been issued in 
relation to the target country or if the transfer does constitute a transfer to the 
United States in accordance with the Privacy Shield system, the transfer shall 
occur by means of employing the standard clauses approved by the European 
Commission.

The data controller may transfer personal data in accordance with the data 
protection legislation and within the boundaries imposed by same to the 
employees working in the data controller’s own oversees network, to the data 
controller’s subsidiaries and subcontractors and to its service providers retained 
for data processing.
         </p>
        </div>
        <div class="principles-for-protecting">
          <h6 class=" title-header">9. PRINCIPLES FOR PROTECTING THE REGISTER</h6>
          <p class="title">Manual material
Any material to be retained on paper is stored in locked facilities equipped with 
access control. The data controller’s personnel have undertaken confidentiality 
obligations.</p>
          <h6 class="title-header">Data to be processed electronically</h6>
<p class="title">Personnel access to the electronic data content of the register has been 
protected with personal user IDs and passwords. Utilisation of some of the data 
content of the register has been restricted to a limited group of users. The 
environment has been protected with appropriate firewalls and other technical 
safeguards.</p>
<p class="title">The purpose of the above-mentioned measures is to secure the confidentiality, 
availability and integrity of the personal data to be stored in the register, 
as well as the implementation of data subjects’ rights.
  
</p>
        </div>
        <div class="use-of-location-data">
          <h6 class=" title-header">10. USE OF LOCATION DATA</h6>
          <p class="title">In order to use certain Mongolia.travel online services that enable the person to 
find things to do or places to visit nearby, this may require location tracking of 
the user’s device. In such cases user’s consent for identifying location is 
requested each time before using the service.
         </p>
        </div>
        <div class="autonated-decision">
          <h6 class=" title-header">11. AUTOMATED DECISION-MAKING</h6>
          <p class="title">The information in the register shall not be utilised for decision-making entailing 
legal effects for the person and that is based on automated data processing, 
such as profiling.
         </p>
        </div>
        <div class="use-subject's">
          <h6 class=" title-header">12. DATA SUBJECT’S RIGHT TO OBJECT TO THE PROCESSING OF PERSONAL DATA</h6>
          <p class="title">The data subject shall have the right, in connection with their personal specific 
circumstances, to object to profiling pertaining to themselves and to other 
processing measures directed by the data controller at the data subject’s
personal data to the extent the data processing is based upon the data 
processor’s legitimate interests.
         </p>
         <p class="title">The data subject may present their claim regarding the objection in accordance 
with section 15 of this privacy policy. In conjunction with the claim, the data 
subject must specify the specific circumstances based on which they are 
objecting to the processing. The data controller may refuse to carry out the 
request pertaining to the objection on the grounds stipulated for under the 
legislation.
         </p>
        </div>
        <div class="data-subject-marketing">
          <h6 class=" title-header">13. DATA SUBJECT’S RIGHT TO OBJECT TO DIRECT MARKETING</h6>
          <p class="title">The data subject may issue the Data Controller consents or prohibitions 
pertaining to direct marketing on a channel-specific basis, including profiling 
taking place for direct marketing purposes.
         </p>
        </div>
        <div class="other-data">
          <h6 class=" title-header">14. OTHER DATA SUBJECT’S RIGHTS PERTAINING TO THE PROCESSING OF PERSONAL DATA</h6>
          <p class="title">Data subject’s right to obtain access to the information (Right of Access)
The data subject shall have the right to inspect which data concerning them has 
been stored in the register. The inspection request must be submitted in 
accordance with the instructions set forth in this privacy policy. The right of 
access may be denied upon grounds stipulated in the law. As a point of 
departure, exercising one’s right of access in an ordinary manner is free of 
charge.
         </p>
         <p class="title">Data subject’s right to require the rectification or erasure of data or restriction of 
processing</p>
          <p class="title">To the extent the data subject is able to act for themselves, the data subject 
shall, without any undue delay, after becoming aware of the error, or, having 
detected the error themselves, rectify, erase or supplement any piece of 
information found in the register being contrary to the purpose of the register, 
erroneous, unnecessary, deficient or outdated.
          </p>
          <p class="title">To the extent, the data subject is not able to rectify the information themselves, 
the correction request shall be submitted in accordance with section 15 of this 
privacy policy.
          </p>
          <p class="title">The data subject shall also have the right to require
the data controller to restrict the processing of their personal data, 
for instance in circumstances where the 
data subject is awaiting the data controller’s response to their request regarding 
the correction or erasure of their personal data.
Data subject’s right to lodge a complaint with the supervisory authority
The data subject shall have the right to lodge a complaint with the competent 
supervisory authority, if the data controller has not complied with the applicable 
data protection regulation in its operations.
          </p>
        </div>
        <div class="contacts">
          <h6 class=" title-header">15. CONTACTS</h6>
          <p class="title">In all questions concerning the processing of personal data and situations 
related to the exercise of the data subject’s rights, the data subject should 
contact the data controller. The data subject may exercise their rights by 
contacting <a href="info@mongolia.travel" class="link">info@mongolia.travel</a>
         </p>
        </div>
        <div class="versions">
          <h6 class=" title-header">16.  VERSIONS</h6>
          <p class="title">This privacy policy was updated on March 22, 2020.</p>
            <p class="title" >
The data controller follows the developments in legislation and will develop its 
operations constantly, and consequently, retains the right to update this privacy 
policy.
         </p>
        </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";

export default {
  name: "PrivacyPage",
  components: {
    PageLayout
  }
};
</script>

<style lang="scss" scoped>
.privacy-page {
  top:0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  
  .header{
    font-weight: bold;
    width:100%;
    font-size:57px;
  }
  
}
.privacy-page{
  .title-header{
      margin-top:30px;
      font-weight: bold;
    }
    .title{
      margin-top:20px;
      white-space: pre;
      text-align: left;
      margin: 20px 0px 16px;
      .link{
        color: red;
      }
  }
}
// .privacy-page .data-controller {
//     // line-height : normal;
//     .title-header{
//       margin-top:30px;
//       font-weight: bold;
//     }
//     .title{
//       margin-top:20px;
//       white-space: pre;
//       text-align: left;
//       margin: 20px 0px 16px;
//   }
// }
</style>
